import '../external/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/uicons-solid-rounded/css/uicons-solid-rounded.css';
import '../external/bootstrap/css/bootstrap.css';
import $ from 'jquery';
import Popper from 'popper.js';
import '../external/bootstrap/js/bootstrap.js';
import './Projects.css';
import React, { useEffect, useState } from 'react';
import ProjectCard from './ProjectCard';
import { useNavigate, Link } from 'react-router-dom';

function Projects () {

    const [details, setDetails] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect( () => {
        fetch('/projects.json',
        {
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
        })
        .then((response) => response.json())
        .then((data) => setDetails(data))
        .then(setLoaded(true))
        }, []);


    if (details == null) {
        return (
            <div>
                <p>details are null</p>
            </div>
        )
    }
    else{
        return (
            <>
                <div>
                    <div>
                        <h6 className='breadcrumb'>
                            <Link to="/" style={{"textDecoration": "none", "color": "white"}}>
                                <span className='breadcrumb-span'>Home</span> 
                            </Link>
                            
                                <span>
                                    {'\xa0'+ '>' + '\xa0'} 
                                </span>
                            <Link to="#" style={{"textDecoration": "none", "color": "white"}}>
                                <span className='breadcrumb-span'>Projects</span> 
                            </Link>
                            
                        </h6>
                    </div>
                    <div style={{"textAlign" : "left", "padding": "5px 15px"}}>
                        <h1 className='proj-title'>Projects</h1>
                        <div className='home-hr' style={{"backgroundColor" : "#02735E", "marginBottom" : "20px"}}></div>
                        <div className='row'>
                            {details.projects.map((project) => {
                                return(
                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                        <ProjectCard project={project} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default Projects;