import '../external/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/uicons-solid-rounded/css/uicons-solid-rounded.css';
import '../external/bootstrap/css/bootstrap.css';
import $ from 'jquery';
import Popper from 'popper.js';
import '../external/bootstrap/js/bootstrap.js';
import './Experience.css';
import React, { useEffect, useState } from 'react';

function Experiences(props) {
    const experiences = props.experiences;

    const  experience_displays = experiences.map((experience, exIndex) => 
    <div>
        <h2 style={{"marginBottom" : "3px"}}><span className='experience_header'>{experience.position}, {experience.department} @ {experience.company}</span>{'\xa0'.repeat(2)}<span className='experience-date'>{experience.dates}</span></h2>
        <div style={{"padding" : "0px 10px"}}>
            <h4>
            <i className="fi fi-rr-marker experience-location">{'\xa0'.repeat(2) + experience.location}</i>
            </h4>
            <ul>
            {experience.blurbs.map((blurb) => {
                return (
                    <li className='experience-blurb'>{blurb}</li>
                )
            })}
            <p style={{"marginTop" : "10px"}}>
                {
                    experience.highlights.map((highlight, index) => {
                        return(
                        <span key={index} id={"highlight-" + highlight} className="badge badge-primary tag-badge">{highlight} </span>
                        )
                    })
                }
            </p>
            </ul>
            {(exIndex < experiences.length-1) ? <hr style={{"borderColor": "gray", "marginLeft" : "25px", "marginRight" : "25px"}}></hr> : <></>}
            
        </div>
    </div>
    );

    return experience_displays;
}

export default Experiences;