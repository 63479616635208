import '../external/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/uicons-solid-rounded/css/uicons-solid-rounded.css';
import '../external/uicons-brands/css/uicons-brands.css';
import '../external/bootstrap/css/bootstrap.css';
import $ from 'jquery';
import Popper from 'popper.js';
import '../external/bootstrap/js/bootstrap.js';
import './App.css';
import React from 'react';
import toggleSidebar from '../custom';
import { useEffect, useState } from 'react';
import  Modal from 'react-modal';
import { Routes, Route, Link, useLocation } from "react-router-dom";
import Home from './Home.js';
import Projects from './Projects';
import Documents from './Documents';
import Unleash from './Unleash';

function App() {

  const [navOpen, setNavOpen] = useState(false);
  const location = useLocation();
  const [showContact, setShowContact] = useState();
  const [showPopupMenu, setShowPopupMenu] = useState(false);

  function openContacts() {
    document.getElementById('contact-icon').classList.replace('fi-rr-comment', 'fi-rr-cross-circle');
    setShowContact(true);
  }

  function closeContacts() {
    document.getElementById('contact-icon').classList.replace('fi-rr-cross-circle', 'fi-rr-comment');
    setShowContact(false);
  }

  function openMenu() {
    document.getElementById('mobile-menu').classList.toggle('menu-visible');
    setShowPopupMenu(true);
  }
  
  function closeMenu() {
    document.getElementById('mobile-menu').classList.toggle('menu-visible');
    setShowPopupMenu(false);
  }

  const contactModalStyles = {
    content: {
      top: '25%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -0%)',
      textAlign: "left"
    },
  };

  function toggleNav (event) {
    toggleSidebar()
    setNavOpen(!navOpen)
  }


  return (
    <div className="App" id="App" style={{ "backgroundColor": "#014040" }}>
      <div className='container-fluid' id="main-container" style={{"overflowY": "auto", "height": "100vh", "backgroundColor": "#014040", "display": "flex",  "paddingLeft": "0px", "paddingRight": "0px" }}>
          <div className="l-navbar unshown" id="nav-bar">
            <div id="burger" className='nav_button' onClick={toggleNav} >
                <i id="menu-icon" className='fi fi-rr-list nav_icon'></i>
            </div>
            <div style={{"borderRadius" : "3px", "border" : "none", "background" : "#F27405", "margin" : "10px", "marginLeft" : "10px", "height" : "3px", "marginBottom" : "15px"}}></div>
            <Link to={"/"} style={{"textDecoration" : "none"}}>
              <div className='nav_button'>
                <span style={{"verticalAlign": "middle", "textAlign" : "left"}}>
                  <i className='fi fi-rr-home nav_icon'></i>
                  {navOpen ? 
                    <h2 className='unselectable nav_button_text'>{'\xa0'.repeat(2)}Home</h2>
                    : <div></div>
                  }
                </span>
              </div>
            </Link>
            <Link to={"/projects/"} style={{"textDecoration" : "none"}}>
              <div className='nav_button'>
                <span style={{"verticalAlign": "middle"}}>
                  <i className='fi fi-rr-keyboard nav_icon'></i>
                  {navOpen ? 
                    <h2 className='unselectable nav_button_text'>{'\xa0'.repeat(2)}Projects</h2>
                    : <div></div>
                  }
                </span>
              </div>
            </Link>
            <Link to={"/documents/"} style={{"textDecoration" : "none"}}>
            <div className='nav_button'>
              <span style={{"verticalAlign": "middle", "display": "inlineBlock"}}>
                <i className='fi fi-rr-document nav_icon'></i>
                {navOpen ? 
                  <h2 className='unselectable nav_button_text'>{'\xa0'.repeat(2)}Documents</h2>
                  : <div></div>
                }
              </span>
            </div>
            </Link>
          </div> {/* navbar end */}

          

          {/* container main start */}
            <div className='container-fluid' style={{"width": "100%", "margin": "0px", "padding": "0px"}}>
                <a  className="top-menu">
                  <div className='top-menu-content'>
                    <img src='cropped.jpg' style={{"height" : "35px", "width": "35px", "borderRadius": "50%", "marginTop": "0px"}}></img>
                    <h6 className='top-menu-text'>Dwayne Thomson</h6>
                  </div>
                    
                  <i id='menu-icon' onClick={openMenu} style={{"color" : "white", "fontSize": "40px"}} className="fa fi-rr-menu-burger menu-icon-float"></i>
                </a>
              <div  className="container" id="main" style={{"backgroundColor" : "#014040", "paddingBottom" : "10%",  "height": "100"}}>
                
                <Routes>
                  <Route path='/' element={<Home/>} />
                  <Route path='/projects/' element ={<Projects/>} />
                  <Route path='/documents/' element ={<Documents/>} />
                  <Route path='/projects/unleash/' element={<Unleash/>} />
                </Routes>
                  <footer className='footer' id='footer'>
                    <h6>Copyright 2022</h6>
                  </footer>
              </div>

            </div>
            
        <div>

        
        <div id="mobile-menu" className='top-menu-float'  isOpen={true} onRequestClose={closeMenu} ariaHideApp={false} backgroundColor="black" overlayClassName="Menu-Overlay" centered contentLabel="Contact">
            <h5 style={{"fontFamily" : 'roboto', "fontSize" : "30px", "fontWeight" : "300"}}>Menu</h5>   
            <hr style={{"borderColor" : "white"}}></hr>
            <div style={{"textAlign": "center", "marginBottom" : "10px"}}>
              <ul style={{"textAlign" : "center", "listStyle" : 'none', "paddingLeft": "0px"}}>
                <li className='menu-li'>
                <Link to={"/"} style={{"textDecoration" : "none"}}>
                <span onClick={closeMenu} className="badge badge-primary top-menu-badge"><i  class="fi fi-rr-home top-menu-icon">{'\xa0'.repeat(2)}Home</i></span>
                </Link>
                </li>
                <li className='menu-li'>
                <Link to={"/projects/"} style={{"textDecoration" : "none"}}>
                <span onClick={closeMenu} className="badge badge-primary top-menu-badge"><i class="fi fi-rr-keyboard top-menu-icon">{'\xa0'.repeat(2)}Projects</i>  </span>
                </Link>
                </li>
                <li className='menu-li'>
                <Link to={"/documents/"} style={{"textDecoration" : "none"}}>
                <span onClick={closeMenu} className="badge badge-primary top-menu-badge"><i class="fi fi-rr-document top-menu-icon">{'\xa0'.repeat(2)}Documents</i>  </span>        
                </Link>
                </li>
              </ul>
              <div style={{"textAlign" : "middle"}}>
                <span onClick={closeMenu} className="badge badge-primary menu-close-badge"><i class="fi fi-rr-cross top-menu-icon">{'\xa0'.repeat(2)}Close</i>  </span>
              </div>
            </div>
        </div>

          {/* This is the chat bubble */}
        <a  className="float" onClick={openContacts}>
          <i id='contact-icon' style={{"color" : "white", "fontSize": "40px", 'verticalAlign': "middle"}} className="fa fi-rr-comment my-float"></i>
        </a>
        <Modal className='contact-float'  isOpen={showContact} onRequestClose={closeContacts} ariaHideApp={false} backgroundColor="black" overlayClassName="Overlay" centered contentLabel="Contact">
            <h5 style={{"fontFamily" : 'roboto', "fontSize" : "30px", "fontWeight" : "300"}}>Let's get in touch!</h5>   
            <hr style={{"borderColor" : "white"}}></hr>
            <div style={{"textAlign": "left", "marginBottom" : "10px"}}>
              <a href='https://www.linkedin.com/in/dwaynethomson/' target="_blank">
                <span className="badge badge-primary contact-badge"><i  class="fi fi-brands-linkedin contact-icon">{'\xa0'.repeat(2)}Contact on LinkedIn</i></span>
              </a>
              <a href='mailto:dwaynethomson14@gmail.com'>
                <span className="badge badge-primary contact-badge"><i class="fi fi-rr-envelope contact-icon">{'\xa0'.repeat(2)}Contact by Email</i>  </span>
              </a>
              <a href='https://github.com/chartsNgraphs/' target='_blank'>
                <span className="badge badge-primary contact-badge"><i class="fi fi-brands-github contact-icon">{'\xa0'.repeat(2)}Visit Github</i>  </span>        
              </a>
            </div>
        </Modal>

        
      </div>
     
        


    </div>
    </div>
  );
}

export default App;
