import '../external/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/uicons-solid-rounded/css/uicons-solid-rounded.css';
import '../external/bootstrap/css/bootstrap.css';
import $ from 'jquery';
import Popper from 'popper.js';
import '../external/bootstrap/js/bootstrap.js';
import './Documents.css';
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

function Documents () {

    const [documents, setDocuments] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate();

    useEffect( () => {
        fetch('/documents.json',
        {
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
        })
        .then((response) => response.json())
        .then((data) => setDocuments(data))
        .then(setLoaded(true))
        }, []);

    function showButton (id) {
        document.getElementById(id).classList.toggle("download-button-active");
    }
    function hideButton(id) {
        document.getElementById(id).classList.toggle("download-button-active");
    }

    if (documents == null) {
        return (
            <div>
                <p>docs are null</p>
            </div>
        )
    }
    else{
        return (
            <>
                <div>
                    <div>
                        <h6 className='breadcrumb'>
                            <Link to="/" style={{"textDecoration": "none", "color": "white"}}>
                                <span className='breadcrumb-span'>Home</span> 
                            </Link>
                            
                                <span>
                                    {'\xa0'+ '>' + '\xa0'} 
                                </span>
                            <Link to="#" style={{"textDecoration": "none", "color": "white"}}>
                                <span className='breadcrumb-span'>Documents</span> 
                            </Link>
                            
                        </h6>
                    </div>
                    <div style={{"textAlign" : "left", "padding": "5px 15px"}}>
                        <span>
                            <h1 className='doc-title'>Library</h1>
                        </span>
                        <div className='home-hr' style={{"backgroundColor" : "#02735E", "marginBottom" : "20px"}}></div>
                        <div className='home-card'>
                        <i className="fi fi-rr-document section-icon">{'\xa0'.repeat(3)}Documents</i>
                        <div className='home-hr' style={{"height" : "2px", "marginTop" : "5px"}}></div>
                            <div style={{"padding": "20px 0px", "verticalAlign": "middle"}}>
                                {documents.documents.map((doc) => {
                                    return(
                                        <div className='document' onMouseEnter={() => showButton(doc.name + "-button")} onMouseLeave={()=> hideButton(doc.name + "-button")}>
                                            <div className='row'>
                                                <div className='col'>
                                                    <span className='document-title' ><i class="fi fi-rr-document-signed section-icon"></i></span>
                                                    <span className='document-title'>{'\xa0'.repeat(3)}{doc.name}</span>
                                                </div>
                                                <div className='col'>
                                                    <a href={"/docs/"+doc.path} download={doc.name}>
                                                        <button id={doc.name + "-button"} className='download-button' ><i class="fi fi-rr-download" style={{"color":"white","fontSize":"15px", "fontStyle":"normal"}}>Download</i></button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                               
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default Documents;