import '../external/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/uicons-solid-rounded/css/uicons-solid-rounded.css';
import '../external/bootstrap/css/bootstrap.css';
import $ from 'jquery';
import Popper from 'popper.js';
import '../external/bootstrap/js/bootstrap.js';
import './Unleash.css';
import React, { useEffect, useState } from 'react';
import ProjectCard from '../components/ProjectCard';
import { useNavigate, Link } from 'react-router-dom';

function Unleash () {

    const [details, setDetails] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [stats, setStats] = useState(null);
    const [statsLoaded, setStatsLoaded] = useState(false);
    const navigate = useNavigate();


    useEffect( () => {
        fetch('https://portfolio-stats-aggregator.azurewebsites.net/api/pypistats?code=y30DhG4TFRINzWWWM-1q7K8P6K8XiLBZZ-g6SiYtDcbUAzFuszTeTg==',{
            method:'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
           })
        .then((response) => response.text())
         .then((data) => setStats(JSON.parse(data)))
        
        
    }, [])

    function copyPip() {
        /* Get the text field */
        var copyText = document.getElementById("pip-span");

            /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText.textContent);
        
        /* Alert the copied text */
        alert("Copied command: " + copyText.textContent);
        }


    return (
        <>
            <div>
                <div>
                    <h6 className='breadcrumb'>
                        <Link to="/" style={{"textDecoration": "none", "color": "white"}}>
                            <span className='breadcrumb-span'>Home</span> 
                        </Link>
                        <span>
                            {'\xa0'+ '>' + '\xa0'} 
                        </span>
                        <Link to="/projects" style={{"textDecoration": "none", "color": "white"}}>
                            <span className='breadcrumb-span'>Projects</span> 
                        </Link>
                        <span>
                                {'\xa0'+ '>' + '\xa0'} 
                        </span>
                        <Link to="#" style={{"textDecoration": "none", "color": "white"}}>
                            <span className='breadcrumb-span'>ml_unleash</span> 
                        </Link>
                        
                    </h6>
                </div>
                <div style={{"textAlign" : "left", "padding": "5px 15px"}}>
                    <h1 className='proj-title'>ml_unleash</h1>
                    <div className='home-hr' style={{"backgroundColor" : "#02735E", "marginBottom" : "20px"}}></div>
                    <div className='row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                            <div className='home-card-center'>
                                <div className='pip-install-display'>
                                    <span id="pip-span" className='pip-text' style={{"display" : "inline-block"}}>pip install ml_unleash</span>
                                    <div onClick={copyPip} className='pip-install-copy-background'>
                                        <i className="fi fi-rr-copy copy-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                            <div className='home-card-center'>
                                <a className='pypi-button' href='https://pypi.org/project/ml-unleash/' target={'_blank'}>
                                    View on PyPi
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='home-card'>
                        <i className="fi fi-rr-align-left section-icon">{'\xa0'.repeat(3)}Overview</i>
                        <div className='home-hr' style={{"height" : "2px", "marginTop" : "5px"}}></div>
                        <p className='overview-text'>
                            The gap between development and production is smaller than ever, thanks to ml_unleash. The package quickly builds models into light, efficient web apps, and containerizes them using docker for deployment into VM hosts or your favorite container orchestration solution, like Kubernetes.
                        </p>
                    </div>
                    <div className='home-card'>
                        <i className="fi fi-rr-chart-histogram section-icon">{'\xa0'.repeat(3)}Statistics</i>
                        <div className='home-hr' style={{"height" : "2px", "marginTop" : "5px"}}></div>
                        <div className='row'>
                            <div className='col-lg-4 col-sm-12 col-md-4'>
                                <div className='stats-card-center'>
                                    <h1>{stats ? stats.total.toLocaleString("en-US") : "fetching..."}</h1>
                                    <h6>Total Downloads (last 6 months)</h6>
                                </div>
                            </div>
                            <div className='col-lg-4 col-sm-12 col-md-4'>
                                <div className='stats-card-center'>
                                    <h1>{stats ? stats.last_30_days.toLocaleString("en-US") : "fetching..."}</h1>
                                    <h6>Last 30 Days</h6>
                                </div>
                            </div>
                            <div className='col-lg-4 col-sm-12 col-md-4'>
                                <div className='stats-card-center'>
                                    <h1>{stats ?  stats.last_week.toLocaleString("en-US") : "fetching..."}</h1>
                                    <h6>Last Week</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    

}

export default Unleash;