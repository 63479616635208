import '../external/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/uicons-solid-rounded/css/uicons-solid-rounded.css';
import '../external/bootstrap/css/bootstrap.css';
import $ from 'jquery';
import Popper from 'popper.js';
import '../external/bootstrap/js/bootstrap.js';
import './ProjectCard.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';


function ProjectCard (props) {
    const project = props.project;

    return (
        <div className='project-card'>
            <h3 className='project-title'>{project.name}</h3>
            <div className='project-hr'></div>
            <p style={{"marginTop" : "15px"}} className='project-description'>{project.description}</p>
            <div>
                <h5 style={{"color" : "white", "fontFamily" : "roboto", "fontWeight": "400", "fontSize": "14px"}}>Technologies</h5>
                {
                    project.skills.map((skill, index) => {
                        return(
                        <span key={index} id={"skill-" + skill} className="badge badge-primary skill-badge">{skill} </span>
                        )
                    })
                }
            </div>
            <div style={{"textAlign" : "right", "padding" : "10px", "paddingTop" : "30px"}}>
                {project.path_type!="external" ? 
                <Link style={{"textDecoration": "none"}} to={'/projects/'+project.details_path}>
                    <button className='project-details-button'>Learn More</button>
                </Link>
                :
                <a style={{"textDecoration": "none"}} href={"https://"+project.details_path} target={"_blank"}>
                    <button className='project-details-button'>Learn More</button>
                </a>
                }
            </div>
        </div>
    )
}

export default ProjectCard;