

function toggleSidebar() {
    let sidebar = document.getElementById("nav-bar")
    let main = document.getElementById("main-container")
    sidebar.classList.toggle("unshown")
    // main.classList.toggle("padded")
    document.getElementById("menu-icon").classList.toggle("fi-rr-arrow-left")
    document.getElementById("menu-icon").classList.toggle("fi-rr-list")
    let buttons = document.querySelectorAll(".nav_button");
    console.log(buttons.length)
    for(var i = 0; i < buttons.length; i++){
        if(buttons[i].id != "burger"){
            buttons[i].classList.toggle("nav_button_open")
        }
        else{
            buttons[i].classList.toggle("left-menu-close")
        }
        
        
    }
}

export default toggleSidebar;