import '../external/uicons-regular-rounded/css/uicons-regular-rounded.css';
import '../external/uicons-solid-rounded/css/uicons-solid-rounded.css';
import '../external/bootstrap/css/bootstrap.css';
import $ from 'jquery';
import Popper from 'popper.js';
import '../external/bootstrap/js/bootstrap.js';
import './Home.css';
import React, { useEffect, useState } from 'react';
import Experiences from './Experience';
import { Link } from 'react-router-dom';

function Home() {

    const [details, setDetails] = useState(null);

    useEffect( () => {
        fetch('/details.json',
        {
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
        })
        .then((response) => response.json())
        .then((data) => setDetails(data))
        }, []);
    

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "//cdn.credly.com/assets/utilities/embed.js";
        script.type = "text/javascript";
        document.body.appendChild(script);
        }, []);

    if (details == null) {
        return (
            <div></div>
        )
    }
    else{
        return (
            <>
            <div >
                <div className='row'>
                    <div className='col-lg-2'>
                        <span className='outer-circle'>
                            <img src='cropped.jpg' style={{"height" : "100px", "width": "100px", "borderRadius": "50%", "marginTop": "10px"}}></img>
                        </span>
                    </div>
                    <div className='col-lg-10 my-auto' style={{"textAlign" : "left"}}>
                        <h1 className='home-title'>{details.personal.firstname} {details.personal.lastname}</h1>
                        <div className='home-hr' style={{"backgroundColor" : "#02735E"}}></div>
                    </div>
                </div>
            </div>
            <div className='home-card' id='education-card'>
                <span style={{"textAlign" : "left"}}>
                <i className="fi fi-rr-graduation-cap section-icon">{'\xa0'.repeat(3)}Education</i>
                <div className='home-hr' style={{"height" : "2px", "marginTop" : "5px"}}></div>
                </span>
                <span style={{"marginTop" : "15px"}}>
                    <h2 style={{"marginTop" : "10px", "fontSize" : "20px", "color": "white", "fontFamily" : 'roboto', "fontWeight" : "400"}}>{details.education.college}, Class of {details.education.grad_year}</h2>
                    <p style={{"marginTop" : "10px", "marginBottom" : "10px"}}><span className='major-caption'>Major: </span><span className='major-details'>{'\xa0'.repeat(2) + details.education.major}</span></p>
                    <p style={{"marginTop" : "10px", "marginBottom" : "10px"}}><span className='major-caption'>Minor: </span><span className='major-details'>{'\xa0'.repeat(2) + details.education.minor}</span></p>
                </span>
            </div>
            
            <Link to={"/projects/"} style={{"textDecoration" : "none"}}>
                <div className='quick-action' id='portfolio-link-card'>
                    <div className='row px-5'>
                        <div className='col-11 ' style={{'textAlign' : "left", "padding": "5px 10px", "display": "flex", "alignItems": "center"}}>
                            <h1 style={{"fontWeight" :"300", "fontFamily": "roboto", "color" : "white", "fontSize" : "22px", "marginBottom" : "0px", "display": "inline"}}>Go to Project Portfolio</h1>
                        </div>
                        <div className='col-1' style={{"textAlign": "right", "padding": "5px 5px"}}>
                            <i className="fi fi-rr-arrow-right link-icon" style={{"marginRight" : "5px", "verticalAlign" : "middle"}}></i>
                        </div>
                    </div>
                </div>
            </Link>
            <div className='home-card' id='work-card'>
            <span style={{"textAlign" : "left"}}>
                <i className="fi fi-rr-briefcase section-icon">{'\xa0'.repeat(3)}Experience</i>
                <div className='home-hr' style={{"height" : "2px", "marginTop" : "5px"}}></div>
                <Experiences experiences={details.experience}></Experiences>
            </span>
            </div>

            <div className='home-card' id='certification-card'>
                <div>
                    <span style={{"textAlign" : "left"}}>
                        <i className="fi fi-rr-check section-icon">{'\xa0'.repeat(3)}Certifications</i>
                    </span>
                </div>
                <div className='home-hr' style={{"height" : "2px", "marginTop" : "5px"}}></div>
                
                <div style={{"display": "flex"}} className='row'>
                    <div className='cert-div'>
                        <div data-iframe-width="150" data-iframe-height="270" data-share-badge-id="c2dddb1d-9cb2-4990-b428-e183c5ad8372" data-share-badge-host="https://www.credly.com"></div>
                    </div>
                    <div className='cert-div'>
                        <div data-iframe-width="150" data-iframe-height="270" data-share-badge-id="9cd99ba9-b492-4f7a-9ca0-ec79cd49c168" data-share-badge-host="https://www.credly.com"></div>
                    </div>
                    <div className='cert-div'>
                        <div data-iframe-width="150" data-iframe-height="270" data-share-badge-id="529e1c8a-72cc-447f-832c-e1753fb5c3f9" data-share-badge-host="https://www.credly.com"></div>
                    </div>
                    <div className='cert-div'>
                        <div data-iframe-width="150" data-iframe-height="270" data-share-badge-id="cbb7d4d7-840b-4a71-9e48-e208d42cce74" data-share-badge-host="https://www.credly.com"></div>
                    </div>
                    
                </div>
            </div>

            <div className='home-card' id='skills-card'>
            <span style={{"textAlign" : "left"}}>
                <div>
                    <i className="fi fi-rr-settings section-icon">{'\xa0'.repeat(3)}Skills</i>
                </div>
                <div className='home-hr' style={{"height" : "2px", "marginTop" : "5px", "marginBottom": "15px"}}></div>
                {
                    details.skills.map(
                        (skill, index) => {
                            return(
                                <span key={index} id={"skill-" + skill} className="badge badge-primary skill-badge">{skill} </span>
                            )
                        }
                    )
                }
            </span>
            </div>
            </>
    )
}
    
}

export default Home;